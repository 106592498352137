<!--
 * @Author: 肖槿
 * @Date: 2020-06-12 15:37:57
 * @Description: 小店管理
 * @LastEditors: 肖槿
 * @LastEditTime: 2020-06-16 17:03:50
 * @FilePath: \s2b-admin\src\pages\admin\shop\index.vue
-->

<template>
  <div id="merchant">
    <!--    <p class="bread-crumbs">{{route.name}} / {{subRouteName}}</p>-->
    <!--    <p class="bread-crumbs">
      <a-icon v-if="isshowgoback" class="back-btn" type="rollback" @click="$router.go(-1)" />
      {{ route.name }} / {{ subRouteName }}
    </p>-->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Shop',
  data() {
    return {
      isshowgoback: false,
      route: null,
      subRouteName: null,
    };
  },
  created() {
    // 初次渲染面包屑
    this.renderNav();

    // 监听路由变化，修改顶部面包屑文字
    // this.$watch("$route", to => (this.subRouteName = to.name));
    // 监听路由变化，修改顶部面包屑文字
    this.$watch('$route', to => {
      let threePath = '';
      if (this.$route.path.split('/').length > 4) {
        this.isshowgoback = true;
        threePath = this.$route.path.split('/')[3];
      } else {
        this.isshowgoback = false;
      }
      this.subRouteName =
        (threePath !== ''
          ? this.route.children.find(item => item.path === threePath).name + ' / '
          : '') + to.name;
    });
  },
  methods: {
    renderNav() {
      const route = this.$store.state.user.routes;
      this.route = route
        .find(item => item.path === '/admin')
        .children.find(item => item.path === 'merchant');
      let subPath = this.$route.path.split('/')[3];
      let threePath = '';
      if (this.$route.path.split('/').length > 4) {
        this.isshowgoback = true;
        threePath = this.$route.path.split('/')[3] + '/' + this.$route.path.split('/')[4];
      } else {
        this.isshowgoback = false;
      }
      this.subRouteName =
        this.route.children.find(item => item.path === subPath).name +
        (threePath !== ''
          ? ' / ' + this.route.children.find(item => item.path === threePath).name
          : '');
    },
  },
};
</script>

<style lang="less">
#merchant {
  .less-table {
    &.ant-table-wrapper {
      flex: 1;
      display: flex;
      .ant-spin-nested-loading {
        position: inherit;
        height: 100%;
        display: flex;
        overflow-y: auto;
        overflow-x: hidden;
        width: 100%;
        .ant-spin-container {
          position: inherit;
          -webkit-transition: opacity 0.3s;
          transition: opacity 0.3s;
          display: flex;
          flex-direction: column;
          width: 100%;
          .ant-table-pagination.ant-pagination {
            text-align: right;
          }
          .ant-table-fixed-header .ant-table-body-inner {
            overflow-y: auto !important;
          }
          .ant-table-body {
            min-height: calc(100vh - 320px);
          }
          .ant-table-placeholder {
            margin-top: calc(200px - 60vh);
            margin-bottom: calc(40vh - 46px);
            border: none;
          }
        }
      }
    }
  }

  .btn-lines {
    .ant-btn {
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 5px 10px !important;
    line-height: 20px;
    font-size: 12px;
    .ant-btn {
      color: #e83741 !important;
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      line-height: 1 !important;
      text-shadow: none;
      &.look {
        color: rgba(0, 0, 0, 0.75) !important;
        &:hover {
          color: #e83741 !important;
        }
      }
    }
  }

  .ant-table-pagination.ant-pagination .ant-pagination-options-quick-jumper,
  .ant-pagination.mini .ant-pagination-total-text,
  .ant-pagination.mini .ant-pagination-simple-pager {
    font-size: 12px;
  }
  .ant-form-item .ant-form-item-label,
  .ant-form-item .ant-form-item-label label,
  .ant-form-item .ant-form-item-control-wrapper,
  .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control,
  .ant-form-item .ant-form-item-control-wrapper .ant-form-item-control ant-input-affix-wrapper,
  .ant-input,
  .ant-btn,
  .ant-select,
  .ant-select-selection__rendered,
  .ant-select-selection--single {
    font-size: 12px;
    height: 28px;
    line-height: 27px;
  }
  .ant-form-item .ant-form-item-label,
  .ant-form-item .ant-form-item-label label,
  .ant-form-item-control,
  .ant-input,
  .ant-btn {
    line-height: 26px;
    display: inline-block;
  }
  .ant-form-item {
    margin-bottom: 8px;
    margin-right: 12px;
    .ant-input-affix-wrapper .ant-input:not(:last-child) {
      padding: 0 12px;
    }
  }
  .ant-table-thead tr {
    height: 32px;
  }
  /deep/ .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
  .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }

  background: #fff;
  padding: 16px 16px 0;
  overflow: hidden;
  .ant-form-item-label {
    width: 40px;
  }
  .shop-id-item {
    .ant-form-item-label {
      width: 55px;
    }
  }
  .bread-crumbs {
    font-size: 12px;
    color: #666;
    padding: 8px 10px;
    background: #f0f0f0;
    margin-bottom: 10px;
  }
}
.certified {
  color: #333;
}
.certifing {
  color: #fa8c16;
}
.certifiy {
  color: #87d068;
}
.certifiy-fail {
  color: #f50;
}
</style>
<style lang="less" scoped>
/deep/ .ant-table-scroll .ant-table-body {
  //样式穿透
  overflow-x: scroll !important;
}
.ant-table-scroll .ant-table-body {
  //样式穿透
  overflow-x: scroll !important;
}
</style>
